import React from "react";
import logo from "../../../images/packages/logo.svg";
import Subtract from "../../../images/packages/Subtract.svg";

interface HoverCardProps {
  items?: string[];
  title?: string;
  closeCard: () => void;
}

const HoverCard: React.FC<HoverCardProps> = ({ items, title, closeCard }) => {
  return (
    <div className="hoverCard-body">
      <img className="hoverCard-top" src={Subtract} alt="shwara" />
      <svg
        onClick={closeCard}
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Close"
        className="hoverCard-close" // Add a class for styling the close icon
      >
        <path
          d="M18 6L6 18"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {title && (
        <p className="hoverCard-title">
          <span>{title}</span>
        </p>
      )}
      <ul className="list-disc hoverCard-description">
        {items?.map((item: string, index: number) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <img className="hoverCard-logo" src={logo} alt="shwara" />
    </div>
  );
};

export default HoverCard;
